<template>
  <div>
    <p class="font-weight-bold m-2" style="font-size: 27px; color: black">MyAssets</p>
    <div id="fileManager" />

    <v-dialog v-model="showPreview">
      <v-card>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="showPreview = false" fab>
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="mx-auto d-flex justify-center">
          <video
            v-if="typeFile === 'video'"
            style="max-width: 950px; max-height: 700px"
            controls
            contain
            autoplay
            :src="linkFile"
          ></video>
          <v-img v-else-if="typeFile === 'image'" contain height="700" :src="linkFile"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import { mdiClose, mdiCloudUpload, mdiFolderOutline, mdiFolderPlus } from '@mdi/js'
// import { fileManagerModal, fileManager, entityFormFilePicker, textFormFilePicker } from 'mini-file-manager'
import { fileManager } from '@/helpers/mini-file-manager'
import confirm from '@/views/component/Confirm.vue'
import fileManagerOptions from './options/optionsFileManager.js'
import './style.css'

export default {
  name: 'My Assets',
  components: {
    confirm,
  },
  data() {
    return {
      icons: {
        mdiFolderOutline,
        mdiFolderPlus,
        mdiCloudUpload,
        mdiClose,
      },
      photoLinks: [],
      linkFile: '',
      showPreview: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: this.linkFile,
            type: 'video/mp4',
          },
        ],
      },
      typeFile: '',
      dialogDelete: false,
    }
  },
  mounted() {
    this.openFileManager()

    window.addEventListener('passingUrl', event => this.updateQueryParamFromUrl(event))
    window.addEventListener('confirmDelete', event => this.confirmDelete(event))
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    showPreview(val) {
      if (!val) {
        this.linkFile = ''
      }
    },
  },
  methods: {
    openFileManager() {
      fileManager('#fileManager', fileManagerOptions(this.user.sub_id), this.photoLinks, this.saveFile)
    },
    updateQueryParamFromUrl(event) {
      if (event.detail && this.linkFile !== event.detail) {
        this.linkFile = event.detail.url
        this.typeFile = event.detail.typeFile
        this.showPreview = true
      }
    },
    confirmDelete(event) {
      if (!this.dialogDelete) {
        this.dialogDelete = true
        this.$refs.confirm
          .open(
            this.$t('delete'),
            `this file
            ?`,
            { color: 'red' },
            this.$t('delete'),
          )
          .then(async agree => {
            // console.log(deleteConfirm(), 'sayang confirm')
            if (agree) {
              // deleteConfirm(event)
              this.dialogDelete = false
            } else {
              // deleteConfirm(event, true)
              this.dialogDelete = false
            }
          })
      }
    },
    async saveFile(data) {
      const images = Promise.all(
        data.map(async el => {
          const res = 'https://fm.prod.marketa.id/uploads/' + el.uploadRelativePath
          this.photoLinks.push(res)
        }),
      )
    },
  },
}
</script>

<style></style>
